import React from "react"
import MainLayout from '../layouts/MainLayout'
import { graphql } from "gatsby"
import SEO from '../components/SEO'
import Page from '../components/Page'

export default function Pages({ data }) {
  return (
    <MainLayout>
      <SEO title={data.strapiPages.Seo.Title} description={data.strapiPages.Seo.Description} />
      <Page data={data.strapiPages} />
    </MainLayout>
  )
}

export const pageQuery = graphql`  
query($slug: String!) {
    strapiPages(Slug: { eq: $slug }) {
      Description
      Slug
      Title
      Seo {
        Description
        Keywords
        Title
      }
      Slider {
        Title
        Url
        DesktopImage {
          localFile {
            publicURL
          }
        }
        MobileImage {
          localFile {
            publicURL
          }
        }
      }
      Card {
        Url
        Title
        Description
        Image {
          localFile {
            childImageSharp {
              gatsbyImageData(
                layout: FULL_WIDTH
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
      }
    }
  }
`
